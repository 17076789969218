.gallery_pill_label {
    --margin: 5px;
    position: absolute;
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - var(--margin) * 2);
    margin: var(--margin);
    bottom: 0;
    right: 0;
}

.gallery_pill_label.left {
    right: unset;
    left: 0;
}

.gallery_pill_label.top {
    bottom: unset;
    top: 0;
}

.gallery_image_border {
    position: absolute;
    border-radius: 5px;
    border: 3px solid transparent;
    background-color: transparent;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
