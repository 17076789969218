.notification_list {
    z-index: 1000;
    position: fixed;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: end;
    flex-direction: column;
    right: 0;
    bottom: 0;
    width: fit-content;
    max-width: 300px;
    padding: 10px;
    gap: 5px;
    pointer-events: none;
}

.notification {
    display: flex;
    align-items: start;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    gap: 10px;
    border-radius: 8px;
    color: black;
    background-color: whitesmoke;
    font-size: 1rem;
    word-wrap: break-word;
    word-break: break-word;
    pointer-events: auto;
}

.notification_icon {
    color: rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    min-width: fit-content;
    min-height: fit-content;
}

.notification.success {
    color: white;
    background-color: mediumseagreen;
}

.notification.warning {
    color: black;
    background-color: gold;
}

.notification.error {
    color: white;
    background-color: tomato;
}
