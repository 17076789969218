.form_modal {
    width: 600px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
}

.form_modal > .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
