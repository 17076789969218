.topbar {
    width: 100%;
    height: var(--topbar-height);
    min-height: var(--topbar-height);
    max-height: var(--topbar-height);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    border-bottom: var(--border-width) solid var(--secondary-color);
}

button.drawer_button {
    color: var(--foreground-color);
}

button.drawer_button:hover {
    color: var(--foreground-color-shade);
}

.sidebar {
    height: 100%;
    width: var(--sidebar-width);
    min-width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-right: var(--border-width) solid var(--secondary-color);
    overflow-y: auto;
    padding: 20px;
    gap: 20px;
}

.sidebar_background {
    z-index: 997;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.4s ease, visibility 0.4s ease;
}

.sidebar_background.hidden {
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
}

.sidebar_background .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: left 0.4s ease, visibility 0.4s ease;
}

.sidebar_background.hidden .sidebar {
    left: calc(var(--sidebar-width) * -1);
}

.sidebar > .buttons {
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sidebar > .buttons > * {
    padding: 10px;
}

.sidebar_profile {
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--secondary-color-shade);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 15px;
}

.sidebar_profile > .buttons {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.profile_picture {
    aspect-ratio: 1;
    width: 50%;
    max-width: 50%;
    min-width: 50%;
    height: auto;
    border-radius: 50%;
    background-color: var(--background-color);
    border: var(--border-width) solid var(--secondary-color);
    position: relative;
}

.profile_picture .layer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    position: absolute;
    pointer-events: none;
}

.profile_picture img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    transition: opacity 0.2s ease;
}

.profile_picture img.hidden {
    opacity: 0;
}

.profile_text {
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
}

.profile_text .role {
    font-size: var(--small-font-size);
    color: var(--foreground-color-shade);
}

.confirm_logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    gap: 10px;
}

.confirm_logout > p {
    padding: 10px;
}

.confirm_logout > .buttons {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.topbar,
.sidebar_background {
    display: none;
}

@media (max-width: 700px) {
    .sidebar {
        display: none;
    }

    .topbar,
    .sidebar_background,
    .sidebar_background .sidebar {
        display: flex;
    }
}
