:root {
    --foreground-color: #000000;
    --background-color: #ffffff;
    --primary-color: #4a9ee3;
    --secondary-color: #d3d3d3;
    --accent-color: #fa6360;

    --foreground-color-shade: color-mix(
        in srgb,
        var(--foreground-color),
        var(--background-color)
    );
    --secondary-color-shade: color-mix(
        in srgb,
        var(--background-color),
        var(--secondary-color) 30%
    );

    --danger-color: #fa6360;

    color: var(--foreground-color);
    background-color: var(--background-color);
    border-color: var(--secondary-color);
}
