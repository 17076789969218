.file_input {
    width: 100%;
    height: var(--input-height);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--secondary-color);
}

.file_input input {
    display: none;
    visibility: hidden;
}

.file_input button {
    width: 120px;
    min-width: 120px;
    height: 100%;
    border-radius: 0;
    border-right: var(--border-width) solid var(--secondary-color);
}

.file_input button.upload:hover {
    color: var(--primary-color);
}

.file_input button.clear:hover {
    color: var(--danger-color);
}

.file_input p {
    width: 100%;
    height: 100%;
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    text-wrap: nowrap;
    overflow: hidden;
}

.file_input p.drag_active {
    color: var(--primary-color);
}

.file_input p.not_selected {
    color: var(--foreground-color-shade);
}
