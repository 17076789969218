.table {
    height: fit-content;
    width: fit-content;
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    border: 2px solid lightgray;
}

.table_column {
    width: fit-content;
    display: flex;
}

.table_column_content {
    resize: horizontal;
    appearance: none;
    width: fit-content;
    min-width: 100px;
    max-width: 500px;
    overflow: hidden;
    border-left: 2px solid lightgray;
    border-right: 2px solid lightgray;
}

.table_column_content.fit_content {
    resize: none;
    width: fit-content;
    min-width: fit-content;
    max-width: fit-content;
}

.table_column_content.addition {
    resize: none;
    min-width: 110px;
    max-width: fit-content;
}

.table_column_content::-webkit-resizer {
    background-color: transparent;
}

.table_cell {
    width: 100%;
    height: 42px;
    padding: 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: clip;
    border-bottom: 2px solid lightgray;
}

.table_cell.header {
    color: gray;
    font-weight: bold;
    background-color: whitesmoke;
}

.table_column_content.addition .table_cell {
    justify-content: space-between;
}

.table_column_splitter {
    width: 4px;
    min-width: 4px;
    max-width: 4px;
    flex: 1;
    cursor: col-resize;
    border-bottom: 2px solid lightgray;
}

.table_column_splitter.disabled {
    cursor: default;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.table_loading_icon {
    color: lightgray;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.table_icon_circle {
    aspect-ratio: 1;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: lightgray;
    background-color: whitesmoke;
    border: 2px solid lightgray;
    font-weight: bold;
    font-size: 0.5rem;
}

.table_icon_circle.selected,
.table_icon_circle.add {
    color: white;
    background-color: dodgerblue;
}

.table_icon_circle.remove {
    color: white;
    background-color: tomato;
}
