:root {
    --full-page-height: 100lvh;
    --full-page-width: 100lvw;
    --border-width: 2px;
    --border-radius: 10px;
    --topbar-height: 60px;
    --sidebar-width: 200px;
    --input-height: 35px;

    --large-font-size: 20px;
    --font-size: 16px;
    --small-font-size: 12px;
}
