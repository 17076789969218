.text_box {
    width: 100%;
    height: fit-content;
    min-height: var(--input-height);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 10px;
    border-radius: var(--border-radius);
    background-color: var(--secondary-color-shade);
    border: var(--border-width) solid var(--secondary-color);
    text-wrap: nowrap;
}

.text_box.faded {
    color: var(--foreground-color-shade);
}

.text_box.wrap {
    text-wrap: wrap;
    word-wrap: break-word;
    word-break: break-word;
}
