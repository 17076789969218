* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    overscroll-behavior: none;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    caret-color: var(--primary-color);
}

html,
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    font-size: var(--font-size);
}

::-webkit-scrollbar {
    background-color: whitesmoke;
    position: absolute;
}

::-webkit-scrollbar:vertical {
    width: 10px;
    max-width: 10px;
    min-width: 10px;
}

::-webkit-scrollbar:horizontal {
    height: 10px;
    max-height: 10px;
    min-height: 10px;
}

::-webkit-scrollbar-thumb {
    border: 2px solid whitesmoke;
    border-radius: 5px;
    background-color: lightgray;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
    text-align: center;
    font-weight: bold;
}

a,
p {
    text-align: center;
}

a {
    transition: color ease 0.2s;
}

button,
input,
textarea,
select {
    border: none;
    outline: none;
    appearance: none;
    box-shadow: none;
    text-decoration: none;

    color: var(--foreground-color);
    background-color: transparent;
    font-size: var(--font-size);
    font-family: inherit;
}

input::placeholder,
textarea::placeholder {
    color: var(--foreground-color-shade);
}

textarea {
    resize: vertical;
}
