.pill {
    --pill-font-size: var(--small-font-size);
    --pill-height: calc(var(--pill-font-size) / 3 * 5);
    min-height: var(--pill-height);
    max-height: var(--pill-height);
    height: var(--pill-height);
    min-width: var(--pill-height);
    max-width: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: calc(var(--pill-height) / 5);
    border-radius: 5px;
    border: var(--border-width) solid var(--secondary-color);
    background-color: var(--secondary-color-shade);
    font-weight: bold;
    font-size: var(--pill-font-size);
    cursor: default;
}

.pill > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pill.colored {
    --pill-fg-color: var(--foreground-color);
    --pill-bg-color: var(--secondary-color-shade);
    border: var(--border-width) solid
        color-mix(in srgb, var(--pill-bg-color), black 20%);
    color: var(--pill-fg-color);
    background-color: var(--pill-bg-color);
}

.pill.circular {
    border-radius: calc(var(--pill-height) / 2);
}

.pill.clickable {
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.pill.clickable:hover {
    opacity: 0.5;
}
