.tree_view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.tree_view_node {
    display: flex;
    padding-left: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
}

.tree_view_node > .lines {
    --line-weight: 3px;
    --line-length: 18px;
    display: flex;
    width: fit-content;
    height: 100%;
    margin-right: var(--line-weight);
}

.tree_view_node > .lines * {
    background-color: var(--foreground-color);
}

.tree_view_node > .lines > .vertical {
    width: var(--line-weight);
    min-width: var(--line-weight);
    height: 100%;
}

.tree_view_node > .lines > .vertical.last {
    height: calc(var(--line-weight) + var(--line-length));
}

.tree_view_node > .lines > .horizontal {
    position: relative;
    margin-top: var(--line-length);
    height: var(--line-weight);
    min-height: var(--line-weight);
    width: var(--line-length);
}

.tree_view_node > .lines > .horizontal > .dot {
    --size: calc(var(--line-weight) * 2);
    --top: calc(calc(var(--line-weight) - var(--size)) / 2);
    --right: calc(var(--size) * -0.5);
    position: absolute;
    aspect-ratio: 1;
    width: var(--size);
    min-width: var(--size);
    height: auto;
    border-radius: 50%;
    top: var(--top);
    right: var(--right);
}

.tree_view_node > .node {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.tree_view_node > .node > .header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tree_view_node > .node > .header > .title {
    font-size: var(--small-font-size);
    font-weight: bold;
    margin-left: 4px;
}

.tree_view_node > .node > .header > .title > span {
    color: var(--foreground-color-shade);
}

.tree_view_node > .node > .header.no_content {
    cursor: default;
}

.tree_view_node > .node > .content {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tree_view_node > .node > .content:not(.visible) {
    display: none;
    visibility: hidden;
}

.expanded_card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}
