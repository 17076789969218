.monaco_editor {
    width: 100%;
    border: 2px solid lightgray;
}

.monaco_editor.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading {
    color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.loading_text {
    font-weight: bold;
}

.loading_icon {
    font-size: 1.5rem;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
