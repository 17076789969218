.drawer {
    width: 100%;
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--secondary-color);
}

.drawer > .header {
    color: var(--foreground-color-shade);
    display: flex;
    height: var(--input-height);
    width: auto;
}

.drawer > .header > p {
    width: 100%;
    height: 100%;
    color: var(--foreground-color-shade);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 10px;
}

.drawer > .header > .arrow {
    aspect-ratio: 1;
    height: 100%;
    color: var(--secondary-color);
    font-size: var(--font-size);
    border-left: var(--border-width) solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.drawer > .content {
    border-top: var(--border-width) solid var(--secondary-color);
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

.drawer > .content.hidden {
    display: none;
}
