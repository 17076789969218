.tooltip {
    z-index: 4;
    position: fixed;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
    width: fit-content;
    max-width: max(200px, min(400px, calc(var(--full-page-width) / 2)));
    padding: 10px;
    gap: 5px;
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--secondary-color);
    background-color: var(--background-color);
}

.tooltip.hidden {
    visibility: hidden;
    display: none;
}

.tooltip > p {
    color: var(--foreground-color-shade);
    text-align: left;
    font-size: var(--small-font-size);
    text-wrap: unset;
    word-wrap: break-word;
    word-break: break-word;
}
