.login {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login .banner,
.login .form {
    flex: 1;
    min-height: fit-content;
    min-width: fit-content;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login .banner {
    color: var(--background-color);
    background-color: var(--foreground-color);
    padding: 20px;
}

@media (max-width: 700px) {
    .login {
        flex-direction: column;
    }

    .login .banner {
        flex: unset;
        height: fit-content;
    }
}

.login .banner h2 {
    color: var(--foreground-color-shade);
}

.login .form form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 20px;
    gap: 10px;
}

.login .form form h2 {
    width: 100%;
    text-align: left;
}
