.dropdown {
    width: 100%;
    height: var(--input-height);
    min-height: var(--input-height);
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--secondary-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown select {
    width: 100%;
    height: 100%;
    padding-inline: 10px;
    color: var(--foreground-color);
    border-radius: 0;
}

.dropdown .arrow {
    aspect-ratio: 1;
    height: 100%;
    color: var(--secondary-color);
    font-size: var(--font-size);
    border-left: var(--border-width) solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
