.asset_image {
    aspect-ratio: 2;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color-shade);
}

.asset_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: opacity 0.2s ease;
}

.asset_image img.hidden {
    opacity: 0;
    visibility: hidden;
}

.info_modal {
    width: 500px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
}

.info_modal > .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.confirm_delete {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.confirm_delete > .info {
    background-color: var(--secondary-color-shade);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: 5px;
}

.confirm_delete > .info p {
    font-size: var(--small-font-size);
    color: var(--foreground-color-shade);
    text-align: left;
}

.confirm_delete > .message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    gap: 10px;
}

.confirm_delete > .message > p {
    padding: 10px;
}

.confirm_delete > .message > .buttons {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
