.text_input {
    width: 100%;
    height: var(--input-height);
    min-height: var(--input-height);
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--secondary-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_input input {
    height: 100%;
    width: 100%;
    padding-inline: 10px;
}

.text_input .password_icon {
    color: var(--foreground-color-shade);
    padding-inline: 5px;
    width: fit-content;
    height: fit-content;
}

.text_input .suffix {
    color: var(--foreground-color-shade);
    background-color: var(--secondary-color-shade);
    width: fit-content;
    height: 100%;
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: var(--border-width) solid var(--secondary-color);
    text-wrap: nowrap;
}
