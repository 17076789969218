.masonry {
    --column-width: 250px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--column-width), 1fr));
    gap: 10px;
}

.masonry > * {
    width: 100%;
    height: 100%;
}
