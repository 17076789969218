/* Layout */

.app {
    max-height: var(--full-page-height);
    max-width: var(--full-page-width);
    height: var(--full-page-height);
    width: var(--full-page-width);
    display: flex;
    position: relative;
    overflow: hidden;
}

.page {
    --page-height: var(--full-page-height);
    --page-width: calc(var(--full-page-width) - var(--sidebar-width));
    max-height: var(--page-height);
    max-width: var(--page-width);
    height: var(--page-height);
    width: var(--page-width);
    display: flex;
    flex-direction: column;
    overflow: auto;
}

@media (max-width: 700px) {
    .app {
        flex-direction: column;
    }

    .page {
        --page-height: calc(var(--full-page-height) - var(--topbar-height));
        --page-width: var(--full-page-width);
    }
}

.page.cover_window {
    --page-height: var(--full-page-height);
    --page-width: var(--full-page-width);
}

.page.scroll_more::after {
    --space-height: calc(var(--page-height) - 100px);
    content: '';
    height: var(--space-height);
    min-height: var(--space-height);
    visibility: hidden;
}

.border_bottom {
    border-bottom: var(--border-width) solid var(--secondary-color);
}

.flex_row,
.flex_column {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.flex_column {
    flex-direction: column;
}

.flex_row.padding,
.flex_column.padding {
    padding: 10px;
}

.flex_row.full_width,
.flex_column.full_width {
    width: 100%;
}

/* Button */

button {
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    word-wrap: nowrap;
    text-wrap: nowrap;
    cursor: pointer;
    border-radius: var(--border-radius);
    color: var(--foreground-color);
    transition: color ease 0.2s;
}

button:hover,
button:disabled,
button:hover:disabled {
    color: var(--foreground-color-shade);
}

button:disabled {
    cursor: auto;
}

button.solid {
    height: var(--input-height);
    min-height: var(--input-height);
    padding-inline: 10px;
    color: var(--background-color);
    background-color: var(--primary-color);
    transition: background-color ease 0.2s;
}

button.solid.danger {
    background-color: var(--danger-color);
}

button.solid:hover,
button.solid:disabled,
button.solid:hover:disabled {
    color: var(--background-color);
    background-color: var(--secondary-color);
}

/* Text */

.clickable {
    cursor: pointer;
    text-decoration: underline;
}

.align_left {
    text-align: left;
}

/* Animations */

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 3s infinite linear;
}
