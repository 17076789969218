.gallery {
    --column-width: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--column-width), 1fr));
    gap: calc(var(--column-width) / 10);
}

.gallery.highlight > *:first-child {
    grid-column: span 2;
    grid-row: span 2;
}

.gallery.no_grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: unset;
}

.gallery > p {
    color: var(--foreground-color-shade);
    font-weight: bold;
}

.gallery_image {
    aspect-ratio: 1;
    width: 100%;
    height: auto;
    border-radius: 5px;
    background-color: var(--secondary-color-shade);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.4s ease;
}

@keyframes skeleton {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

.gallery_image.skeleton {
    background-color: var(--secondary-color);
    animation: skeleton 1s ease infinite alternate;
}

.gallery_image.skeleton.last {
    background: linear-gradient(to right, var(--secondary-color), transparent);
}

.gallery_image:hover {
    opacity: 0.8;
}

.gallery_image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.2s ease;
}

.gallery_image > img.hidden {
    opacity: 0;
    visibility: hidden;
}

.gallery_image .overlay_layer {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.gallery_image .selection_layer {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
}

.gallery_image .selection_layer .selection_box {
    aspect-ratio: 1;
    width: 20px;
    min-width: 20px;
    height: auto;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border: var(--border-width) solid white;
    border-radius: 50%;
    font-size: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery_image .selection_layer.selected {
    background-color: rgba(0, 0, 0, 0.5);
}

.gallery_image .selection_layer.selected .selection_box {
    background-color: var(--primary-color);
}
