.user_data_field {
    font-size: 0.8rem;
    font-weight: bold;
    word-wrap: break-word;
    word-break: break-word;
    color: gray;
}

.user_data_row {
    display: flex;
    gap: 10px;
}

.user_data_row .flex {
    flex: 1;
}

.data_box {
    width: 100%;
    display: flex;
    flex-direction: column;
}
