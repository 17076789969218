.divider {
    background-color: lightgray;
}

.divider.horizontal {
    height: 2px;
    min-height: 2px;
    max-height: 2px;
    width: 100%;
}

.divider.vertical {
    width: 2px;
    min-width: 2px;
    max-width: 2px;
    height: 100%;
}