.card {
    --width: 400px;
    width: var(--width);
    height: fit-content;
    display: block;
    padding: 10px;
    gap: 10px;
    border-radius: var(--border-radius);
    background-color: var(--secondary-color-shade);
    position: relative;
}

.card.border {
    border: 3px solid transparent;
}

@keyframes skeleton {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

.card.skeleton {
    aspect-ratio: 1;
    height: auto;
    max-height: 250px;
    background-color: var(--secondary-color);
    animation: skeleton 1s ease infinite alternate;
}

.card.skeleton.last {
    background: linear-gradient(to right, var(--secondary-color), transparent);
}

.card * {
    text-align: left;
}

.card > * {
    width: fit-content;
}

.card > *:not(:last-child) {
    margin-bottom: 5px;
}

.card > .toolbar {
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    margin: 0;
    gap: 5px;
}

.card > .header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    max-width: 100%;
    gap: 5px;
}

.card_sub_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
    padding-top: 10px;
    gap: 10px;
}

.card_data {
    word-break: break-word;
    white-space: pre-wrap;
    font-size: var(--small-font-size);
    font-weight: bold;
}

.card_data > .title {
    color: var(--foreground-color-shade);
}
