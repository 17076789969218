.modal_background {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.modal_background.hidden {
    visibility: hidden;
    display: none;
}

.modal {
    width: fit-content;
    height: fit-content;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    border-radius: 5px;
    background-color: var(--background-color);
    border: var(--border-width) solid var(--secondary-color);
    display: flex;
    flex-direction: column;
}

.modal.full_screen {
    width: 100%;
    height: 100%;
}

.modal.hidden {
    visibility: hidden;
    display: none;
}

.modal.draggable {
    z-index: 999;
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
}

.modal > .header {
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 20px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    color: var(--foreground-color-shade);
    background-color: var(--secondary-color-shade);
    user-select: none;
    cursor: default;
}

.modal > .header > button {
    color: var(--foreground-color-shade);
    padding: 0;
}

.modal > .header > button:hover {
    color: var(--foreground-color);
}

.modal > .content {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: inherit;
    overflow-x: hidden;
    overflow-y: scroll;
}
