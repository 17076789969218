.color_input {
    width: 100%;
    height: var(--input-height);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--secondary-color);
    overflow: hidden;
}

.color_input button {
    width: 140px;
    min-width: 140px;
    height: 100%;
    border-radius: 0;
    border-right: var(--border-width) solid var(--secondary-color);
    position: relative;
}

.color_input button > input {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
}

.color_input button.select:hover {
    color: var(--primary-color);
}

.color_input button.clear:hover {
    color: var(--danger-color);
}

.color_input .selection {
    width: 100%;
    height: 100%;
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    text-wrap: nowrap;
    overflow: hidden;
}

.color_input .selection.empty {
    color: var(--foreground-color-shade);
}

.color_input .selection .color_circle {
    aspect-ratio: 1;
    height: calc(100% - 10px);
    width: auto;
    margin-right: 5px;
    border-radius: 50%;
    background-color: transparent;
}
