.label_wrapper {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3px;
}

.label_wrapper label {
    font-size: var(--small-font-size);
    font-weight: bold;
    word-wrap: break-word;
    word-break: break-word;
    color: var(--foreground-color-shade);
}
