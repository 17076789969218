.loading_page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading_spinner {
    color: var(--secondary-color);
    aspect-ratio: 1;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
